<template>
  <div>
    <span
      v-if="num > 0"
      :style="num > 5 ? { color: 'rgba(0, 0, 0, 0.65)' } : { color: '#F78626' }"
    >
      剩余{{ num }}天
    </span>
    <span v-else-if="num < 0" style="color: #f5222d">
      超期{{ Math.abs(num) }}天
    </span>
    <span v-else>今日截止</span>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    date: {
      type: String,
      default: "",
    },
  },
  computed: {
    num() {
      if (moment().format("YYYY-MM-DD") === this.date) {
        console.log("same ");
        return 0;
      } else {
        const value = moment(this.date).diff(moment(), "days");
        console.log("value", this.date, value);
        if (value === 0) {
          return value + 1;
        }
        return value;
      }
    },
  },
};
</script>