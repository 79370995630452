<template>
  <div>
    <Pane />
    <a-card class="container">
      <a-form-model layout="inline" @keyup.enter.native="query">
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-input v-model="form.meetName" placeholder="会议名称" />
            </a-form-model-item>

            <a-form-model-item>
              <a-input v-model="form.name" placeholder="督办事项" />
            </a-form-model-item>

            <a-form-model-item>
              <a-range-picker style="width: 240px" v-model="form.date" />
            </a-form-model-item>
            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>

          <a-col :span="2">
            <div class="right" style="margin-top: 4px">
              <a-button
                v-if="$getPermission($route.path + '/add')"
                @click="$router.push($route.path + '/add')"
                type="primary"
                >新建督办任务</a-button
              >
            </div>
          </a-col>
        </a-row>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        :scroll="scroll"
      >
        <a-table-column title="会议名称" data-index="meetName" ellipsis="true">
        </a-table-column>

        <a-table-column title="督办事项" data-index="name" ellipsis="true" />

        <a-table-column title="督办类型" width="160px">
          <template slot-scope="text">
            <div class="left">
              <DataDictFinder dictType="dblx" :dictValue="text.type" />
              <span> / </span>
              {{ getSubType(text.type, text.subType) }}
            </div>
          </template>
        </a-table-column>

        <a-table-column title="执行人" data-index="assigneeName" width="100px">
        </a-table-column>

        <a-table-column
          title="部门"
          data-index="assigneeDeptName"
          width="160px"
        >
        </a-table-column>

        <a-table-column title="办结时限" width="120px">
          <template slot-scope="text">
            <template v-if="text.isDelayed">
              <span style="padding-right: 4px">
                {{ text.delayedDate }}
              </span>
              <template>
                <a-tooltip>
                  <template slot="title">
                    原定办结时限为{{ text.originalDate }}
                  </template>
                  <a-tag color="red"> 延期 </a-tag>
                </a-tooltip>
              </template>
            </template>
            <template v-else>
              {{ text.originalDate }}
            </template>
          </template>
        </a-table-column>

        <a-table-column
          title="完成时间"
          data-index="finishedDate"
          width="120px"
        >
        </a-table-column>

        <a-table-column title="状态" align="center" width="120px">
          <template slot-scope="text">
            <span v-if="text.status === 'finished'" style="color: green">
              已完成
            </span>
            <span v-else>
              <Status
                :date="text.delayedDate ? text.delayedDate : text.originalDate"
              />
            </span>
          </template>
        </a-table-column>

        <a-table-column title="操作" align="right" width="120px">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                v-if="$getPermission($route.path + '/detail')"
                @click.prevent="
                  $router.push(`${$route.path}/detail?id=${text.id}`)
                "
              >
                详情
              </a>
              <a
                href="#"
                class="danger"
                v-if="$getPermission($route.path + '/delete')"
                @click.prevent="deleteText(text)"
                >终止</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import screen from "@/mixins/screen";
import watermark from "@/mixins/watermark";
import { fetchList, remove } from "@/api/supervise";
import { mapGetters } from "vuex";
import Status from "./components/status";
export default {
  name: "supervise",
  mixins: [screen, watermark],

  components: {
    Status,
  },

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    typeList() {
      return this.findDataDict("dblx");
    },
  },

  activated() {
    this.getList();
  },

  methods: {
    // 查询子类型
    getSubType(type, subType) {
      const obj = this.typeList?.find((item) => item.value === type);
      return obj?.children?.find((item) => item.value === subType).name;
    },

    getList() {
      const { current, pageSize, form } = this;
      const { date } = form;
      let startDate, endDate;
      if (Array.isArray(date) && date.length === 2) {
        startDate = date[0].format("YYYY-MM-DD");
        endDate = date[1].format("YYYY-MM-DD");
      }

      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize,

        originalDateStart: startDate,
        originalDateEnd: endDate,
        ...form,
        date: undefined,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要终止这个督办任务吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
  },
};
</script>